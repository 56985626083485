import { render, staticRenderFns } from "./ReportAccountability.vue?vue&type=template&id=47d753fc&scoped=true&"
import script from "./ReportAccountability.vue?vue&type=script&lang=js&"
export * from "./ReportAccountability.vue?vue&type=script&lang=js&"
import style0 from "./ReportAccountability.vue?vue&type=style&index=0&id=47d753fc&lang=scss&scoped=true&"
import style1 from "./ReportAccountability.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d753fc",
  null
  
)

export default component.exports